import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { App } from '../../../../../shared/models/app';
import { Customer } from '../../../../../shared/models/customer';
import { Event } from '../../../../../shared/models/event';
import { EventCategory } from '../../../../../shared/models/eventcategory';
import { Media } from '../../../../../shared/models/media';
import { Response } from '../../../../../shared/models/response';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from '../../../environments/environment';
import { EventTime } from '../../../../../shared/models/eventtime';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss']
})
export class EventEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Skapa event';
  subheading = 'Här skapar du ett nytt event';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public event: Event = new Event();
  public apps: App[] = [];
  public categories: EventCategory[] = [];
  public customers: Customer[] = [];

  public mediaSearchResult: Media[] = [];
  public s: String;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?profile=false&customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;

  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");
    let copyid = +this.route.snapshot.paramMap.get("copyid");

    if (id != 0) {

      this.heading = 'Redigera Event';
      this.subheading = 'Här redigerar du ett event';

      this.api.getEvent(id).subscribe((data: Event) => {
        this.event = data;

        this.api.getEventCategories(this.event.app.id ?? 1).subscribe((data: EventCategory[]) => {
          this.categories = data;
        })
      });
    }
    else {
      if (copyid) {
        this.api.getEvent(copyid).subscribe((data: Event) => {
          this.event = data;
          this.event.id = 0;
        })
      }
      else
      {
        let time = new EventTime();

        this.event.times = [time];
      }

      this.event.id = 0;
      if (this.data.currentUser.customer.apps.length == 1) {
        if (this.event.apps == null) {
          this.event.apps = [];
        }
        this.event.apps.push(this.data.currentUser.customer.apps[0]);
      }
    }

  }

  ngOnInit() {
    this.apps = this.data.currentUser.apps;

    this.api.getMedias(this.data.currentUser.customer.id).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  app_change() {
    this.api.getEventCategories(this.event.app?.id ?? 1).subscribe((data: EventCategory[]) => {
      this.categories = data;
    })
  }
  
    addTime()
    {
      this.event.times.push(new EventTime());
    }
  
    deleteTime(index)
    {
      this.event.times.splice(index, 1);
    }

  save() {
    this.event.starttime = this.data.fixDate(this.event.starttime);
    this.event.endtime = this.data.fixDate(this.event.endtime);
    this.event.customer = this.data.currentUser.customer;

    this.api.setEvent(this.event).subscribe((data:Response)=>{
      this.event.id = data.id;
      this.api.showSuccess("Sparad", "Eventet sparades");
    }, error => this.api.showError(error));

  }

  img_search() {
    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  addimage(m: Media) {
    this.event.media = m;
  }

  removeImage() {
    this.event.media = null;
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.event.media = data;
      this.DropZone.directiveRef.reset();
    })
  }

  public delete() {
    if (confirm("Är du säker på att du vill ta bort det här eventet?")) {

      this.api.delEvent(this.event.id).subscribe((data:any) => {
        this.router.navigateByUrl('/events');
      });
    }
  }
}

