import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
import { environment } from '../../../environments/environment';
import { App } from '../../../../../shared/models/app';

@Component({
  selector: 'app-admin-proof-reading',
  templateUrl: './admin-proof-reading.component.html',
  styleUrls: ['./admin-proof-reading.component.scss']
})
export class AdminProofReadingComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Alla korrekturer';
  subheading = '';
  icon = 'pe-7s-photo icon-gradient bg-malibu-beach';

  public apps: App[];
  public selectedApps: App[] = [];

  dtOptions = {};

  constructor(private data: DataService, private http: HttpClient,
    private route: ActivatedRoute, private router: Router) {
      this.apps = this.data.currentUser.apps;
      this.selectedApps = this.data.currentUser.selectedApps;
  }

  ngOnInit(): void {
    const that = this;

    let appstr = "";

    if (this.selectedApps) {
      this.selectedApps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }

  }

  ngOnDestroy(): void {
  }

  change() {
    this.data.currentUser.selectedApps = this.selectedApps;
    this.router.navigateByUrl('/news/0', { skipLocationChange: true }).then(
      x => {
        this.router.navigateByUrl('/admin/proofreading', { skipLocationChange: true });
      }
    )
  }
}
