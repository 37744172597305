import { Component, OnInit, ViewChild } from '@angular/core';
import { Ad } from '../../../../../shared/models/ad';
import { DataService } from 'src/app/Services/data.service';
import { HttpClient } from '@angular/common/http';
import { App } from '../../../../../shared/models/app';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-newses',
  templateUrl: './newses.component.html',
  styleUrls: ['./newses.component.scss']
})
export class NewsesComponent implements OnInit {

  @ViewChild('DataTable') DataTable;

  baseUrl: string = environment.apiUrl;
  heading = 'Alla nyheter';
  subheading = '';
  icon = 'pe-7s-photo icon-gradient bg-malibu-beach';

  public ads: Ad[] = [];
  public apps: App[];
  public selectedApps: App[] = [];
  public customer_ids: number[];
  public status = false;

  dtOptions = {};

  constructor(private data: DataService, private http: HttpClient,
    private route: ActivatedRoute, private router: Router) {
    this.apps = this.data.currentUser.apps;
    this.selectedApps = this.data.currentUser.selectedApps;
    this.status = this.data.currentUser.selectedStatus;
      if (this.data.currentUser.selectedCustomer_ids)
        {
          this.customer_ids = this.data.currentUser.selectedCustomer_ids;
        }
        else
        {
          this.customer_ids = [-1, -22, -15];
        }

  }

  public add_customer(id: number) {

    if (!this.customer_ids.includes(id))
    {
      this.customer_ids.push(id);
      this.change();
    }


  };

  public remove_customer(id: number) {
    this.customer_ids = this.customer_ids.filter(x => x != id);

    if (this.customer_ids.length == 0)
    {
      this.customer_ids = [-1];
    }

  this.change();
  }

  ngOnInit(): void {
 
    this.loadTable();

  }

  ngOnDestroy(): void {
  }

  loadTable() {
    const that = this;

    let appstr = "";

    if (this.selectedApps) {
      this.selectedApps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }

    let cutomersstr = this.customer_ids.join(",");

    this.dtOptions = {
      order: [[1, "desc"]],
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable": "Det finns ingen data i tabellen",
        "info": "Visar rad _START_ till _END_ av _TOTAL_ rader",
        "infoEmpty": "Visar post 0 till 0 av 0 poster",
        "infoFiltered": "(av totalt _MAX_ poster)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing": "Laddar...",
        "search": "Sök:",
        "zeroRecords": "Inget matchar sökningen",
        "paginate": {
          "first": "Första",
          "last": "Sista",
          "next": "Nästa",
          "previous": "Föregående"
        },
        "aria": {
          "sortAscending": ": activate to sort column ascending",
          "sortDescending": ": activate to sort column descending"
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/ads?customer_ids=' + cutomersstr + "&appstr=" + appstr + "&status=" + (this.status ? 1 : 0),
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.ads = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'title' }, { data: 'timestart' }]
    };

  }

  public change() {
    this.data.currentUser.selectedApps = this.selectedApps;
    this.data.currentUser.selectedCustomer_ids = this.customer_ids;
    this.data.currentUser.selectedStatus = this.status;
    this.router.navigateByUrl('/news/0', { skipLocationChange: true }).then(
      x => {
        this.router.navigateByUrl('/newses', { skipLocationChange: true });
      }
    )
  }
}
