import { SectionPage } from './../../../../../shared/models/sectionPage';
import { Component, OnInit } from '@angular/core';
import { Section } from '../../../../../shared/models/section';
import { HttpClient } from '@angular/common/http';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-admin-sections-pages',
  templateUrl: './admin-sections-pages.component.html',
  styleUrls: ['./admin-sections-pages.component.scss']
})
export class AdminSectionsPagesComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Alla sektionssidor';
  subheading = 'Här redigerar du alla sektionessidor';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public sectionpages: SectionPage[] = [];

  dtOptions = {};

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      responsive: true,
      language: {
        "emptyTable":     "Tabellen innehåller ingen data",
        "info":           "Visar rad _START_ - _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar 0 av 0 rader",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget innehåll hittat",
        "paginate": {
          "first":      "Första",
          "last":       "Sista",
          "next":       "Nästa",
          "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/sectionpages',
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.sectionpages = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'id' }, { data: 'name' }]
    };

  }

  ngOnDestroy(): void {
  }
}
