import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event } from '../../../../../shared/models/event';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { environment } from '../../../environments/environment';
import { App } from '../../../../../shared/models/app';
import { DataService } from 'src/app/Services/data.service';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventActionsComponent } from 'angular-calendar/modules/common/calendar-event-actions.component';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { ApiService } from 'src/app/Services/api.service';
import { DataTableDirective } from 'angular-datatables';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-admin-events',
  templateUrl: './admin-events.component.html',
  styleUrls: ['./admin-events.component.scss']
})
export class AdminEventsComponent implements OnInit {

  public apps: App[];
  public app: App;

  baseUrl:string = environment.apiUrl;
  heading = 'Alla events';
  subheading = 'Här redigerar du alla events';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public events: Event[] = [];

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions = {};

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.cal_events = this.cal_events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();

  cal_events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;

  constructor(private modal: NgbModal, private http: HttpClient,
    private data: DataService, private api: ApiService, private router: Router) {
  }

  ngOnInit(): void {

    this.apps = this.data.currentUser.apps;
    this.app = this.data.currentUser.default_app;

    if (!this.app)
    {
      this.data.currentUser.default_app = this.apps[0];
      this.app = this.apps[0];
    }

    this.load_events();

  }

  ngOnDestroy(): void {

    this.refresh.unsubscribe();
  }


  load_events(){

    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      responsive: true,
      "order": [[ 2, 'asc' ]],
      language: {
        "emptyTable":     "Tabellen innehåller ingen data",
        "info":           "Visar rad _START_ - _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar 0 av 0 rader",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget innehåll hittat",
        "paginate": {
          "first":      "Första",
          "last":       "Sista",
          "next":       "Nästa",
          "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/events?app_id=' + this.app.id,
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.events = resp.data;

            this.cal_events = [];
            this.events.forEach(item => {


              let cal_item: any = {};

              cal_item.start = new Date(item.starttime);
             // cal_item.end = new Date(item.endtime);
              cal_item.title = formatDate(cal_item.start, "hh:mm", "en-US") + " " + item.title;
              cal_item.actions = this.actions;
              cal_item.id = item.id;
              cal_item.resizable = {
                beforeStart: true,
                afterEnd: true,
              }
              cal_item.draggable =  true; 

              this.cal_events.push(cal_item);

              this.cal_events.sort((a, b) => (a.start > b.start) ? 1 : -1)

            })


            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'id' }, { data: 'title' }, { data: 'starttime' }]
    };

  }


  app_change()
  {

    this.data.currentUser.default_app = this.app;

    this.api.setUser(this.data.currentUser).subscribe(() => {

      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.ajax.reload();
      });


    });


  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.cal_events = this.cal_events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {

    if (action == "Clicked")
    {
      this.router.navigateByUrl("/admin/event/" + event.id);
    }
    else
    {
      this.modalData = { event, action };
      this.modal.open(this.modalContent, { size: 'lg' });
    }


  }


  deleteEvent(eventToDelete: CalendarEvent) {
    this.cal_events = this.cal_events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


}
